/*===============================
	お問い合わせ、確認ボタン
================================*/


.form{
  width: 100%;
  margin-bottom: 40px;
  dt{
    width: 100%;
  }
  dd{
    width: 100%;
    margin-bottom: 10px;
    input,textarea{
      width: 100%;
      box-sizing: border-box;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 2px;
    }
  }
}
.submit {
  display: inline-block;
    text-align: center;
    padding: 10px 20px;
    border-radius: 100px;
    margin-bottom: 20px;
    color: #fff;
    background-color:#0c3667 ;
    &:hover{
          background-color: #4686da;
    }
  }


  
@media (min-width: 576px) {
  form {
    margin: 0 auto;
    .form {
      margin-bottom: 40px;
      dt {
        width: 30%;
        padding: 12px 20px;
        float: left;
        text-align: right;
        clear: left;
      }
      dd {
        width: 70%;
        float: right;
        margin-bottom: 10px;
        input {
          width: 100%;
          box-sizing: border-box;
          border: 1px solid #ccc;
          border-radius: 2px;
        }
        span{
          padding: 12px 20px;
          display: block;
        }
      }
    }
    .submit {
      width: 20%; }
  }
}
