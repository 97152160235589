@import url(http://fonts.googleapis.com/css?family=Lato:300,400);
@font-face {
	font-weight: normal;
	font-style: normal;
	font-family: 'codropsicons';
	src:url('../fonts/codropsicons/codropsicons.eot');
	src:url('../fonts/codropsicons/codropsicons.eot?#iefix') format('embedded-opentype'),
		url('../fonts/codropsicons/codropsicons.woff') format('woff'),
		url('../fonts/codropsicons/codropsicons.ttf') format('truetype'),
		url('../fonts/codropsicons/codropsicons.svg#codropsicons') format('svg');
}

*, *:after, *:before { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }
.clearfix:before, .clearfix:after { content: ''; display: table; }
.clearfix:after { clear: both; }

.codrops-header {
	margin: 0 auto;
	padding: 2em 0 0;
	text-align: center;
}

.codrops-header h1 {
	margin: 0;
	font-weight: 300;
	font-size: 2.5em;
}

.codrops-header h1 span {
	display: block;
	padding: 0 0 0.6em 0.1em;
	font-size: 0.6em;
	opacity: 0.7;
}

/* To Navigation Style */
.codrops-top {
	width: 100%;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 0.69em;
	line-height: 2.2;
	background: #fff;
}

.codrops-top a {
	display: inline-block;
	padding: 0 1em;
	text-decoration: none;
}

.codrops-top span.right {
	float: right;
}

.codrops-top span.right a {
	display: block;
	float: left;
}

.codrops-icon:before {
	margin: 0 4px;
	text-transform: none;
	font-weight: normal;
	font-style: normal;
	font-variant: normal;
	font-family: 'codropsicons';
	line-height: 1;
	speak: none;
	-webkit-font-smoothing: antialiased;
}

.codrops-icon-drop:before {
	content: "\e001";
}

.codrops-icon-prev:before {
	content: "\e004";
}

.codrops-demos {
	margin: 30px auto 0;
	padding: 30px 0;
}

.codrops-demos a,
section button {
	/* display: inline-block;
	background: #a9a9a9; 
	color: #fff;
	font-weight: 400;
	padding: 10px 20px;
	margin: 5px;
	text-transform: uppercase;
	border-radius: 2px;
	letter-spacing: 1px; */
}

.codrops-demos a:hover,
.codrops-demos a:active,
.codrops-demos a.current-demo {
	/* background: #777; */
}

section {
	padding: 1em 2em 5em;
	text-align: center;
	font-size: 1.5em;
}

section p {
	max-width: 600px;
	position: absolute;
    top: 2%;
	right: 0;
	font-size: 22px;
}

section button {
	border: none;
    padding: 0px 10px;
    outline: none;
    color: #0f3667;
    background: none;
}

.related > a {
	/* border: 1px solid #99cc33; */
	display: inline-block;
	text-align: center;
	margin: 20px 10px;
	padding: 25px;
	/* color: #99cc33; */
	opacity: 0.8;
	-webkit-transition: color 0.3s, border-color 0.3s;
	transition: color 0.3s, border-color 0.3s;
	-webkit-backface-visibility: hidden;
}

.related a:hover {
	/* border-color: #82b222;
	color: #82b222; */
}

.related a img {
	max-width: 100%;
}

.related a h3 {
	margin: 0;
	padding: 0.5em 0 0.3em;
	max-width: 300px;
	text-align: left;
	font-size: 65%;
	font-weight: 400;
}

@media screen and (max-width: 25em) {
	.codrops-icon span {
		display: none;
	}
}