/*===============================
問合せ
================================*/

div.mw_wp_form form {
  width: 100%;
}
.contact-form {
  &-title {
    float: left;
    display: inline-block;
    width: 20%;
    clear: both;
    margin: 0;
    text-align: end;
    padding-right: 20px;
  }
  &-field {
    display: inline-block;
    width: 70%;
    margin: 0;
    line-height: 1.5em;
    text-align: left;
  }
}

.contact-2col {
  width: 80%;
  margin: 0 auto;
  margin-right: 85px;
  ul {
    display: flex;
  }
  &-dd {
    width: 77%;
  }
}

.mw_wp_form dl {
  overflow: hidden;
  padding: 10px;
}
.mw_wp_form dl .input_w100 input {
  width: 100%;
  padding: 8px;
}

@media (max-width: 1023px), print {
  .mw_wp_form dl dt {
    width: 100%;
    text-align: left;
    padding-bottom: 6px;
  }
  .mw_wp_form dl dd {
    width: 100%;
  }
  .contact-2col {
    width: 100%;
    ul {
      display: block;
      li:first-child {
        margin-bottom: 30px;
      }
    }
    &-dd {
      width: 100%;
    }
  }
}
