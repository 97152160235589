/*===============================
コラム
================================*/

.column {
  &-title {
    font-size: 20px;
    text-align: left;
    color: #0F3667;
    border-bottom: 1px solid #898989;
  }
  &-text p {
    font-size: 14px;
    margin-bottom: 5px;
  }
  &-date {
    font-size: 14px;
    text-align: right;
    color: #898989;
  }
  &-singledate {
    font-size: 14px;
    color: #898989;
  }
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 480px) {
}


/*===============================
前へ次へページネーション
================================*/

.post-navigation {
  overflow: hidden;
  padding: 12px 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin: 50px 0;
  text-align: center;
}

.past {
  float: left;
  margin: 15px 0;
}

.future {
  float: right;
  margin: 15px 0;
}


/*===============================
関連記事
================================*/

#related-post{}  
#related-post *{
	box-sizing:border-box;
	}
#related-post h3{}
 
 
.related-post-list{
	display:flex;
	flex-wrap:wrap;
}
 
.related-post-list .related-post-sub{
	width:32%;
	margin-right:1%;
	margin-bottom:2%;
	
	padding:0.2em;
	
	box-shadow:0px 0px 3px 1px #EEE;
	-moz-box-shadow:0px 0px 3px 1px #EEE;
	-webkit-box-shadow:0px 0px 3px 1px #EEE;	
	
}
 
.related-post-thumb{
	margin-bottom: 0.5em;
	}
.related-post-thumb img{
	width:100%;
  height: 180px !important;
  object-fit: cover;
}
.related-post-thumb .thumb-noimage{
	text-align:center;
	background:#EEE;
	padding:2em;
	font-size:12px;
	color:#666;
	}
	 
.related-post-content{
	padding:0.5em;
	}
 
.related-post-content h4{
	margin-bottom:1em;
	font-size:12px;
	
}
 
@media only screen and (max-width: 600px){
 
.related-post-list .related-post-sub{
	width:48%;
	margin-right:2%;
}
.related-post-thumb img{
	width:100%;
  height: 100px !important;
  object-fit: cover;
}
}

/*===============================
検索プルダウン
================================*/
.cp_ipselect {
	overflow: hidden;
	width: 90%;
	text-align: center;
}
.cp_ipselect select {
	width: 100%;
	padding-right: 1em;
	cursor: pointer;
	text-indent: 0.01px;
	text-overflow: ellipsis;
	border: none;
	outline: none;
	background: transparent;
	background-image: none;
	box-shadow: none;
	-webkit-appearance: none;
	appearance: none;
}
.cp_ipselect select::-ms-expand {
    display: none;
}
.cp_ipselect.cp_sl02 {
	position: relative;
	border: 1px solid #000000;
	border-radius: 2px;
	background: #ffffff;
}
.cp_ipselect.cp_sl02::before {
	position: absolute;
	top: 0.8em;
	right: 0.9em;
	width: 0;
	height: 0;
	padding: 0;
	content: '';
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-top: 6px solid #000000;
	pointer-events: none;
}
.cp_ipselect.cp_sl02:after {
	position: absolute;
	top: 0;
	right: 2.5em;
	bottom: 0;
	width: 1px;
	content: '';
	border-left: 1px solid #000000;
}
.cp_ipselect.cp_sl02 select {
	padding: 6px 38px 6px 8px;
  color: #000000;
  font-size: 15px;
}

/*===============================
カテゴリー検索フォーム
================================*/

.searchandfilter{
	border: 1px solid #000;
	margin-bottom: 40px;
}

.searchandfilter ul{
    width:100%;
}

.searchandfilter li{
	vertical-align: sub;
}

.searchandfilter li:first-child{
	border-right: 1px solid;
    padding: 6px 20px;
	margin: 8px;
	text-align: center;
	select{
		border:none;
		background:none;
		vertical-align: baseline;
	}
}

.searchandfilter li:nth-child(2){
	padding: 6px;
	margin: 8px;
	width:60%;
	input{
		width: 100%;
		border:none;
	}
}

.searchandfilter li:nth-child(3){
	float: right;
    padding: 12px;
	input:nth-child(2){
		background: none;
    border: 1px solid #000;
    font-family: -webkit-body;
	}
}

// @media only screen and (max-width: 920px){
// 	.searchandfilter li:first-child {
//     border-right: none;
// 	margin: 0px;
// 	border-bottom: 1px solid;
// 	width: 100%;
// }
// }

@media only screen and (min-width:440px)and (max-width:1120px){
.searchandfilter li:nth-child(2){
	width:40%;
}
}

@media only screen and (min-width:768px)and (max-width:852px){
.searchandfilter li:nth-child(2){
	width:45%;
}
}



@media only screen and (max-width:439px){
.searchandfilter li:nth-child(2){
	width: 34%;
}
}
