
/*===============================
文字・タイトル
================================*/

/*【font-size:基準値設定::1440-576pxの幅で設定】*/

html{
      font-size: 62.5%;// 1rem = 10px
}
body{
  font-size:1.4rem;// 1rem = 10px = 16px
  font-family: 'Noto Sans JP';
}

p,li,th,td,a{
  font-size:14px;
  font-size: calc(1.2rem + ((1vw - 0.576rem) * 0.2291));/* 12px~14pxで可変*/
}
// のちにフォールバック記載

a:hover {
 text-decoration: none;
}

/*【title】*/

.title{
  &-h1{
    &-white{
    font-size:42px;
    font-size: calc(4.2rem + ((1vw - 0.576rem) * 0.9163));
    line-height: 50px;
    display: inline-block;
    font-weight: 700;
    color: white;
    .emphasis{
    font-size:132px;
    font-size: calc(11.2rem + ((1vw - 0.576rem) * 2.291));
    }
    }
  }
  &-h2{
    font-size:32px;//IE8以下とAndroid4.3以下用フォールバック
    //font-size: calc(2.8rem + ((1vw - 0.576rem) * 0.4582));/* 28px~32pxで可変*/
    font-size: 33px;
    margin-bottom: 20px;
    line-height: 1;
    font-weight: normal;
    &:after{
      content: "";
      height: 1px;
      display: block;
    }
  }
  &-h3{
    font-size:26px;
    font-size: calc(2.6rem + ((1vw - 0.576rem) * 0.2291));/* 28px~30pxで可変*/
    font-weight: normal;
  }
  &-h4{
    font-size: 26px;//IE8以下とAndroid4.3以下用フォールバック
    font-size: calc(2.2rem + ((1vw - 0.576rem) * 0.2291));/* 22px~26pxで可変*/
    font-weight: normal;
  }
  &-sub{
    font-size: 10px;//IE8以下とAndroid4.3以下用フォールバック
    font-size: calc(1rem + ((0.5vw - 0.576rem) * 0.4582));/* 10px~14pxで可変*/
    letter-spacing: 0px;
    font-weight: normal;
  }
}


/*【list-title】*/

.dl{
  &-text{
    dt{margin-bottom: 10px;
      font-size: 20px;
      font-family: "Sawarabi Mincho";
      font-weight: 100;
      position: relative;
      padding-left: 1.5em;
      &:after {
        position: absolute;
        top: 50%;
        left:0;
        transform:translateY(-50%);
        content: '';
        width: 20px;
        height:3px;
        background-color: #444;
      }
    }
    dd{
      margin-bottom: 20px;
    }
    &-b{
      dt{
        font-family: "Sawarabi Mincho";
        font-weight: 100;
        font-size: 16px;
        margin-bottom: 30px;
      }
    }
  }

  &-QandA{
    margin-bottom: 50px;
    .Q{
      color: #306598;
      margin-bottom: 10px;
      font-size: 16px;
    }
    .A{
      color: #58a3b7;
    }
  }
}

/*【font】*/
.font{
  &-bold{font-weight: bold;}
  &-normal{font-weight: normal;}
  &-size10{font-size: 10px;}
  &-size11{font-size: 11px;}
  &-size12{font-size: 12px;}
  &-size13{font-size: 13px;}
  &-size14{font-size: 14px;}
  &-size15{font-size: 15px;}
  &-size16{font-size: 16px;}
  &-size17{font-size: 17px;}
  &-size18{font-size: 18px;}
  &-size19{font-size: 19px;}
  &-size20{font-size: 20px;}
  &-size21{font-size: 21px;}
  &-size22{font-size: 22px;}
  &-size23{font-size: 23px;}
  &-size24{font-size: 24px;}
  &-size25{font-size: 25px;}
  &-size26{font-size: 26px;}
  &-size27{font-size: 27px;}
  &-size28{font-size: 28px;}
  &-size29{font-size: 29px;}
  &-size30{font-size: 30px;}
  &-size31{font-size: 31px;}
  &-size32{font-size: 32px;}
  &-size33{font-size: 33px;}
  &-size34{font-size: 34px;}
  &-size35{font-size: 35px;}
  &-size36{font-size: 36px;}
  &-size37{font-size: 37px;}
  &-size38{font-size: 38px;}
  &-size39{font-size: 39px;}
  &-size40{font-size: 40px;}
}

/*【text】*/
.text{
  &-left{text-align: left;}
  &-right{text-align: right;}
}

// 小デバイス（横向きモバイル, 576px 以上）
@media (min-width: 576px) {
  // sm
}

// 中デバイス（タブレット, 768px 以上）
@media (min-width: 768px) {
  // md
.md {
    &-font{
      &-size14{font-size: 14px;}
    }
}
}
// 大デバイス（デスクトップ, 992px 以上）
@media (min-width: 992px) {
  // lg
}

// 特大デバイス（ワイド・デスクトップ, 1200px 以上）
@media (min-width: 1200px) {
  // xl
}


