/*===============================
    スタッフ一覧
================================*/

.circular-image-single img {
    border-radius: 50%;
    height: 380px;
    width: 380px;
    object-fit: cover;
    border: 1px solid #ccc;
}

.circular-image-archive img {
    border-radius: 50%;
    width: 180px;
    height: 180px;
    object-fit: cover;
    border: 1px solid #ccc;
}

@media screen and (max-width: 1160px) {
  .circular-image-archive img {
    width: 140px;
    height: 140px;
}

.circular-image-single{
    text-align: center;
    img{
      width:320px;
      height:320px;
    }
}
}

@media screen and (max-width: 768px) {
  .circular-image-single img{
    width:280px;
    height:280px;
  }
}

  .searchform {
    position: relative;
    width: 100%;
    margin: 0;
  }
  .searchfield {
    font-size: 14px;
    width: 100%;
    margin: 0;
    padding: 10px;
    border: solid 1px #ccc;
    background-color: #ffffff;
    border-radius: 8px;
  }
  .searchsubmit {
    position: absolute;
    top: 0;
    right: 1%;
    margin-top: 13px;
    padding: 0;
  }
  #searchsubmit{
    border:1px solid;
  }


/*===============================
    スタッフ詳細
================================*/

.table{
  width:100%;
}
  table tbody tr th{
      width: 40%;
  }
  .table_border tbody tr {
    th, td {
      border-bottom: thin solid #ccc !important;
    }
  }
  .table_text tbody tr {
    th {
      text-align: left;
      color: #0F3667;
    }
    td {
      text-align: left;
    }
  }

  .table tr td{
    border: none;
  }

  .table tr th{
    border: none;
  }


.ly_staffinfo_sp{
  display:flex;
}

  @media screen and (max-width: 768px) {
  .ly_staffinfo_sp{
    display:block;
    text-align: center;
  }
}