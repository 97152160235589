/*===============================
	コンテンツ共通
================================*/

.pagenav-link-box .current {
  color: #fff;
  background-color: #0e3667 !important;
}

/*===============================
	新築分譲中物件
================================*/


.table tbody + tbody {
  border-top: none;
}

.estate {
  margin-bottom: 60px;
  padding: 30px;
  background-color: #f1f1f1;
  &-content{
  }
  &-title{
    border-left: 8px solid #0F3667;
    padding-left: 10px;
    font-weight: normal;
    margin-bottom: 30px;
    a{
      font-size: calc(2rem + ((1vw - 0.576rem) * 0.2291));
    }
  }
  &-img{
    display: inline-block;
    img{
      width: 100%;
    }
  }
  table {
    margin-bottom: 30px;
    tr,
    th,
    td {
      border: none;
      text-align: left;
    }
    tr{
      border-bottom: 1px solid #ccc;
      &:first-child{
        border-top: 1px solid #ccc;
      }
    }
    thead{
      background-color: #0e3667;
      color: #fff;
      th{
        font-weight: normal;
        border: 1px solid #fff;
        text-align: center;
      }
    }
  }
}



.table{
  &-price{
    width: 100%;
    td{
      border: 1px solid #ccc !important;
    } 

  }
}


.estate{
  &-btn{
    width: 100%;
    display: block;
    text-align: center;
    padding: 10px;
    background-color: #ccad1e;
    border-radius: 8px;
    margin-bottom: 20px;
    color: #fff;
    &:hover{
      background-color: #a28500;
      color: #fff;
    }
    &-b{
      display: inline-block;
      text-align: center;
      padding: 10px 20px;
      border-radius: 100px;
      margin-bottom: 20px;
      color: #0c3667;
      border: 1px solid #0c3667;
      &:hover{
        background-color: #0c3667;
        color: #fff;
      }
    }
    &-c{
      display: inline-block;
    text-align: center;
    padding: 10px 20px;
    border-radius: 100px;
    margin-bottom: 20px;
    color: #fff;
    background-color: #d86696;
    &:hover{
      background-color: #a73062;
      color: #fff;
    }
    }
  }
  .slider{
    img{
      width: 100%;
    }
  }
  .slick-prev, .slick-next{
    position: absolute;
    top: -200px;
  }
  .slick-prev{
    left: 10px;
    &:before {
      background-image: url(../img/common/arrow_prev.png);
      content: "　";
      background-repeat: no-repeat;
      background-size: auto;
      width: 30px;
      height: 30px;
      font-size: 30px;
      display: inline-block;
      background-position: left -10px top -12px;
  }
  }
  .slick-next{
    right: 20px;
    &:before {
      transform: scale(-1, 1);
      background-image: url(../img/common/arrow_prev.png);
      content: "　";
      background-repeat: no-repeat;
      background-size: auto;
      width: 30px;
      height: 30px;
      font-size: 30px;
      display: inline-block;
      background-position: left -10px top -12px;
  }
  }
}



.t-block {
  table-layout: fixed;
  border-top: none;
}

.soldout__button {
  background-color: #13407b;
  padding: 2px 6px;
}

.shadow {
  a img {
    transition: 0.3s;
  }
  a img:hover {
    box-shadow: 0 0 8px gray;
  }
}

.wpfp-span {
  background-color: #13407b;
  margin-bottom: 10px;
  display: inline-block;
  border-radius: 100px;
  padding: 8px 12px;
  &:hover {
    background-color: #4686da;
  }
  img{
    border: 0;
    vertical-align: unset;
    margin-right: 0px;
  }
}

.table-block{
    table-layout: fixed;
    border-top: none;
}


.col2-w {
  width: 50%;
}

.col2-w img {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 576px) {
  .col2-w {
    width: 100%;
  }
}

@media (max-width: 1100px) {
  .scroll-table {
  overflow: auto;
  white-space: nowrap;
  table{
    width:100%;
  }
}  
.scroll-table::-webkit-scrollbar{/*tableにスクロールバーを追加*/
 height: 5px;
}
.scroll-table::-webkit-scrollbar-track{/*tableにスクロールバーを追加*/
 background: #F1F1F1;
}
.scroll-table::-webkit-scrollbar-thumb {/*tableにスクロールバーを追加*/
 background: #BCBCBC;
}
.table-block{
  table-layout: auto;
  border-top: none;
}


}











/*===============================
	アコーディオン
================================*/

.accordion-box {
  position: relative;
}
.accordion-box label {
  height: 140px; /* グラデーションの高さ */
  cursor: pointer;
  text-align: center;
  font-size: 12px;
  position: absolute;
  bottom: 0;
  width: 100%;

  /* 以下グラデーションは「背景が白」に併せて設定しています */ 
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.95) 90%);
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.95) 90%);
  background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.95) 90%);
  background: -ms-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.95) 90%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.95) 90%);
}
.accordion-box input:checked + label {
  background: inherit; /* 開いた時には背景グラデーションを消す */
}
.accordion-box label:after {
  content: "続きをよむ"; /* ラベルの文字 */
  letter-spacing: .05em;
  line-height: 2.5rem;
  position: absolute;
  bottom: 20px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  color: #fff;
  background-color: #000;
  width: 18.75rem;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.accordion-box input {
  display: none;
}
.accordion-box .accordion-container {
  overflow: hidden;
  height: 200px; /* 開く前に見えている部分の高さ */
  -webkit-transition: all 0.1s;
  -moz-transition: all 0.1s;
  -ms-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
}


.accordion-box input:checked + label {
  /* display: none ; 閉じるボタンは要らないとき */
}
.accordion-box input:checked + label:after {
  content: "閉じる";
}
.accordion-box input:checked ~ .accordion-container {
  height: auto;
  padding-bottom: 50px; /* 閉じるボタンのbottomからの位置 */
  -webkit-transition: all 0.1s;
  -moz-transition: all 0.1s;
  -ms-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
}




.table-price .w-small{
  width: 10%;
}