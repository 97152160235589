/*
Item: CSS3 Full Responsive Dropdown Menu
Author: http://codecanyon.net/user/JN0iZzze
Version: 1.2
*/

@import url(http://fonts.googleapis.com/css?family=Roboto+Condensed:400,700&subset=latin,cyrillic,greek);
@import url(http://fonts.googleapis.com/css?family=PT+Sans+Narrow:400,700&subset=latin,cyrillic);

/* Colors theme import */

/* @import "colors/green.css";
@import "colors/red.css";
@import "colors/yellow.css";
@import "colors/orange.css";
@import "colors/blue.css";
@import "colors/red-light.css";
@import "colors/gray.css";
@import "colors/violet.css";
@import "colors/blue_glass.css";
@import "colors/red_glass.css";
@import "colors/pink_glass.css";
@import "colors/orange_glass.css";
@import "colors/green_glass.css";
@import "colors/black_glass.css";
@import "colors/wood_glass.css";
@import "colors/violet_glass.css";
@import "colors/navy.css";
@import "colors/dark_violet.css";
@import "colors/black.css";
@import "colors/gray_glass.css";
@import "colors/yellow_glass.css"; */

@import "font-awesome.min.css";

/*******************************************/
/***            Menu Container           ***/
/*******************************************/

.menu_container {
    height: auto;
    clear: both;
    float: left;
    border-radius: 2px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.menu_container.full_width {
    width: 100%;
}

.menu_container > ul {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;
    display:flex;
    width: 100%;
    background: inherit;
    height: 70;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
}

.menu_container > ul > li {
    position: relative;
    background: inherit;
    display: block;
    margin: 0;
}



.menu_container > ul > li > a {
    display: block;
    color: #444444;
    text-decoration: none;
    /* text-shadow: 0px 1px 2px rgba(0,0,0,0.25); */
    font-size: 14px;
    line-height: 28px;
    height: 28px;
    font-family: "Roboto Condensed", sans-serif;
    box-sizing: content-box;
    -moz-box-sizing: content-box;
    transition: background-color 200ms linear;
    -moz-transition: background-color 200ms linear;
    -o-transition: background-color 200ms linear;
    -webkit-transition: background-color 200ms linear;
    
}




.menu_container > ul > li .fa {
    font-size: 14px;
    margin-right: 2px;
}

.menu_container > ul > li.last > a {
    box-shadow: none;
    border-right: none;
}

.menu_container > ul > li:hover {
    margin-left: 0px;
}

.menu_container > ul > li:hover > a {
    /* box-shadow: inset 2px 0px 10px -5px rgba(0,0,0,0.6), inset -2px 0px 10px -5px rgba(0,0,0,0.6); */
}

/** Dropdown block **/

.menu_container .menu_dropdown_block {
    visibility: hidden;
    position: relative;
    /* border: 1px solid #ddd; */
    border-top: none;
    /* box-shadow: 1px 1px 3px rgba(0,0,0,0.2); */
    background: #0F3667;
    top: 100%;
    color: #fff;
    overflow: hidden;
    z-index: 30;
    font-size: 13px;
    font-family: Arial, Helvetica, sans-serif;
    height: 0;
    width: auto;
    font-size: 13px;

    box-sizing: border-box;
    -moz-box-sizing: border-box;

    transition: opacity 100ms linear;
    -moz-transition: opacity 100ms linear;
    -o-transition: opacity 100ms linear;
    -webkit-transition: opacity 100ms linear;
    
}

.menu_container > ul > li.right > .menu_dropdown_block {
    right: 0;
}

.menu_container .menu_dropdown_block p {
    padding: 5px 5px;
    margin-bottom: 10px;
    line-height: 1.7em;
    font-size: 1em;
}

.menu_container .menu_dropdown_block *.small {
    font-size: 11px;
    color: #fff;
}

.menu_container .menu_dropdown_block p.links a {
    text-decoration: none;
}

.menu_container > ul > li:hover > .menu_dropdown_block {
    visibility: visible;
    height: auto;
    min-height:40px;
    max-height:3000px;
}

.menu_container .menu_dropdown_block.full_width {
    left: 0px;
    width: 100%;
    margin-left: 0px;
}

.menu_container .menu_dropdown_block.half_width {
    width: 50%;
}

.menu_container .menu_dropdown_block .md-container {
    padding: 40px 60px;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    background: #0F3667;

}

.menu_container .menu_dropdown_block .md-container:after {
    content: ' ';
    display: block;
    clear: both;
}

.menu_container .menu_dropdown_block .column {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    float: none;
    width: auto;
}

.menu_container .menu_dropdown_block .column .content {
    /* padding: 10px 20px; */
}

.menu_container .menu_dropdown_block h1,
.menu_container .menu_dropdown_block h2 {
    font-family: "PT Sans Narrow",sans-serif;
}

.menu_container .menu_dropdown_block h1 {
    font-size: 24px;
    line-height: 1em;
    font-weight: bold;
    /* margin: 138px 0px; */
    color: #fff;
    /* border-bottom: 2px solid #888; */
    padding-top: 10%;
    display: block;
}

.menu_container .menu_dropdown_block h2 {
    font-size: 18px;
    font-weight: bold;
    line-height: 1em;
    padding: 5px 0 5px 5px;
    margin: 0 0 10px 0;
    color: #fff;
    border-bottom: 1px dotted #ccc;
}

img.auto_width {
    width: auto;
    width: 100%;
}

ul.menu_submenu {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
    font-size: 1em;
}

ul.menu_submenu li {
    display: block;
}

ul.menu_submenu li:nth-child(2n){
    margin-left: 30px;
}

ul.menu_submenu a:nth-child(2n){
    margin-left: 30px;
}


.white-box li {
    width:44%;
}

.white-box li a {
    display: block;
    padding: 5px 16px;
    text-decoration: none;
    color: #0F3667;
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 1.3em;
    background: #fff;
}


.white-box li a:after {
    content: '\203A';
    display: inline-block;
    margin-left: 6px;
    position: relative;
    top: -1px;
    font-weight: bold;
    float: right;
}

ul.menu_submenu li a:hover {
    color: #111;
}

ul.menu_submenu li a > span {
    font-family: Calibri;
    font-size: 10px;
}

/** Submenu block **/

ul.menu_submenu_block {
    list-style: none;
    padding: 0;
    margin: 0;
    /*padding: 10px 0 10px 0;*/
    font-size: 1em;
}

ul.menu_submenu_block li {
    display: block;
    position: relative;
}

ul.menu_submenu_block li a {
    display: block;
    padding: 8px 25px 8px 18px;
    text-decoration: none;
    color: #fff;
    margin: 0;
    white-space: nowrap;
}

ul.menu_submenu_block li:hover > a {
    color: #fff;
}

ul.menu_submenu_block > li.has-submenu > a:after {
    content: "\203A";
    position: absolute;
    font-family: Arial, sans-serif;
    top: 6px;
    right: 10px;
    font-size: 20px;
    line-height: 16px;
    font-weight: bold;
    opacity: 0.7;
}

ul.menu_submenu_block > li.has-submenu:hover > a:after {
    opacity: 1;
}

ul.menu_submenu_block > li > .menu_submenu_block {

    visibility: hidden;
    opacity: 0;
    position: relative;
    width: auto;
    text-align: left;
    min-width: 160px;
    top: 100%;
    height: 0;
    box-shadow: inset 4px 0 0 rgba(0,0,0,0.2);
}

ul.menu_submenu_block > li.has-submenu:hover > .menu_submenu_block {
    visibility: visible;
    opacity: 1;
    transition: opacity 100ms linear;
    -moz-transition: opacity 100ms linear;
    -o-transition: opacity 100ms linear;
    -webkit-transition: opacity 100ms linear;
    height: auto;
}

ul.menu_submenu_block > li > .menu_submenu_block a { padding-left: 30px; }
ul.menu_submenu_block > li > .menu_submenu_block > li > .menu_submenu_block a { padding-left: 40px; }
ul.menu_submenu_block > li > .menu_submenu_block > li > .menu_submenu_block > li > .menu_submenu_block a { padding-left: 50px; }

 .mobile_collapser {
    display: block;
    height: 22px;
    padding: 12px 25px;
    color: #fff;
    /* text-shadow: 0px 1px 2px rgba(0,0,0,0.25); */
    font-size: 16px;
    line-height: 22px;
    font-family: "Roboto Condensed", sans-serif;
    /* box-shadow: inset 0 -1px 1px rgba(0,0,0,0.2); */
} 
 .mobile_collapser:before {
    content: '\2261';
    display: block;
    float: left;
    margin: -1px 8px 0 0;
    font-size: 30px;
} 

#hidden_menu_collapser { display: none; }

#hidden_menu_collapser:checked + ul {
    height: auto;
    min-height:40px;
    max-height:2999px;
}

.menu_container > ul.collapsed {
    height: auto;
    min-height:40px;
    max-height:2999px;
}

/* Mobile(Responsive) */

/*@media only screen and (max-width: 940px) and (min-width: 640px) {

    .menu_container > ul > li > a {
        padding: 8px 15px;
        font-size: 14px;
    }

    .menu_container > ul > li:hover > a {
        padding-left: 16px;
    }

}*/

@media only screen and (min-width: 1279px) {

    .mobile_collapser {
        display: none;
    }

    .menu_container {
        height: 60px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .menu_container > ul {
        transition: all 100ms linear;
        -moz-transition: all 100ms linear;
        -o-transition: all 100ms linear;
        -webkit-transition: all 100ms linear;
        height: 70px;
        background: none;
        overflow: visible;
    }

    .menu_container > ul > li {
        position: static;
        display:flex;
        background: inherit;
        width: 100%;
    }

    .menu_container > ul > li:hover {
        margin-left: 0px;
    }

    .menu_container > ul > li > a {
        box-shadow: none;
        border-right: none;
        width: 100%;
        /* box-shadow: inset -1px 0px 0px rgba(0,0,0,0.2); */
        /* border-right: 1px solid rgba(255,255,255,0.25); */
    }

    .menu_container .menu_dropdown_block {
        position: absolute;
        opacity: 0;
        top: 70px;
    }

    .menu_container > ul > li:hover > .menu_dropdown_block {
        overflow: visible;
        opacity: 1;
        margin-left: -1px;
    }

    .menu_container > ul > li.right {
        float: right;
    }

    .menu_container .menu_dropdown_block .column {

    }

    .menu_container .menu_dropdown_block .column.bordered {
        /* border-right: 1px solid #ddd; */
    }

    ul.menu_submenu_block > li {
    }

    ul.menu_submenu_block > li > .menu_submenu_block {
        position: absolute;
        top: 0;
        height: auto;
        min-width: 160px;
        left: 100%;
        box-shadow: 1px 1px 3px rgba(0,0,0,0.2), inset 1px 0 0 rgba(0,0,0,0.1);
        background: #fff;
    }

    .menu_container .menu_dropdown_block > ul.menu_submenu_block > li > ul.menu_submenu_block {
        margin-left: 0;
    }

    ul.menu_submenu_block > li > .menu_submenu_block a { padding-left: 20px !important; }

    ul.menu_submenu_block.right-align li a {
        text-align: right;
    }

    ul.menu_submenu_block.right-align li.has-dropdown > a:after {
        content: '\2039';
        right: auto;
        left: 10px;
    }

    ul.menu_submenu_block.right-align li a .fa {
        margin-right: 0;
        margin-left: 6px;
    }

    ul.menu_submenu_block.right-align > li .menu_submenu_block {
        left: auto;
        right: 100%;
        box-shadow: 2px 2px 0 rgba(0,0,0,0.1);
    }

    ul.menu_submenu_block.right-align li.has-dropdown > a:after {
        content: '\2039';
        right: auto;
        left: 10px;
    }

    ul.menu_submenu_block > li.has-dropdown:hover > .menu_submenu_block {

    }

    .menu_container .menu_dropdown_block .column.one-col { width: 100%; }
    /* .menu_container .menu_dropdown_block .column.two-col { width: 50%; } */
    .menu_container .menu_dropdown_block .column.three-col { width: 33%; }
    .menu_container .menu_dropdown_block .column.four-col { width: 25%; }
    .menu_container .menu_dropdown_block .column.five-col { width: 20%; }
    .menu_container .menu_dropdown_block .column.six-col { width: 16%; }

    .menu_container .menu_dropdown_block .column.col10per { width: 10%; }
    .menu_container .menu_dropdown_block .column.col20per { width: 20%; }
    .menu_container .menu_dropdown_block .column.col30per { width: 30%; }
    .menu_container .menu_dropdown_block .column.col40per { width: 40%; }
    .menu_container .menu_dropdown_block .column.col50per { width: 50%; }
    .menu_container .menu_dropdown_block .column.col60per { width: 60%; }
    .menu_container .menu_dropdown_block .column.col70per { width: 70%; }
    .menu_container .menu_dropdown_block .column.col80per { width: 80%; }
    .menu_container .menu_dropdown_block .column.col90per { width: 90%; }
} 
/***************/

.menu_container.topfixed {
	position: fixed;
    width: 100%;
	top: 0px;
	left: 0px;
    border-radius: 0;
}

.menu_container.centered > ul {
    float: none;
    margin: 0 auto;
    max-width: 1000px;
}

/* 追加要素 */

.menu_submenu_thumbnail li{
    height: 140px;
    width: 100%;
}

.thumbnail-new{
    background: url(../img/common/recruit-new.png);
    background-repeat: no-repeat;
    position: relative;
    background-size: cover;
}

.thumbnail-new dt{
    position: absolute;
    bottom: 0;
    color: #fff;
    padding: 10px;
}

.thumbnail-new a{
    color: #fff;
    position: absolute;
    bottom: 0px;
    padding: 10px;
    line-height: 20px;
} 

.thumbnail-mid{
    background: url(../img/common/recruit-mid.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.thumbnail-mid dt{
    position: absolute;
    bottom: 0;
    color: #fff;
    padding: 10px;
}

.thumbnail-mid a{
    color: #fff;
    position: absolute;
    bottom: 0px;
    padding: 10px;
    line-height: 20px;
} 


@media only screen and (min-width: 1279px){
    .mobile-navi{
        display:none;
    }
}
@media only screen and (max-width: 1280px){
    .pc-navi{
    display: none;
    }
}


/* @media screen and (min-width: 768px) and (max-width: 940px) {
#content{
    margin-bottom:30px;
}
} */
