/*===============================
TOP
================================*/

.main_img {
  width: 100%;
  display: block;
}

/*===============================
管理戸数・新築分譲実績
================================*/

.home_performance{
  background-color: #8C8C8C;
  p{
    font-weight: bold;
    margin:0px;
    color: #fff;
    span{
      vertical-align: sub;
      font-size: 30px;
      color: #FF4040;
      font-weight: bold;
      padding-left: 10px;
    }
  }
}

.home_performance_item{
  padding: 20px 0px;
}

#video{
  height: 500px;
}

.layer{
    width: 100%;
    object-fit: cover;
    overflow: hidden;
}

@media screen and (min-width: 768px) {
.home_performance_txt{
  line-height: 53px;
}
}


/*
	会員登録・セミナー情報
================================*/


.home {
  &-registry {
        background-color: #0f3667;
    margin-bottom: 100px;
    &-cont{
      background-position: 30px 36px;
      background-size: 70px;
      padding: 20px;
      color: #fff;
      margin:0px;
      background-color: #0F3667;
      &:first-child{
        border-right:1px solid #fff;
      }
      &:hover{
        background-position: 30px 36px;
        background-size: 70px;
        background-color: #668dbd;
        color: #fff;
      }
      &-content{
            justify-content: center;
     align-items: center;
     &-title{
          padding: 0px 0px 0px 80px;
      background:url('../img/common/icon-member.svg')no-repeat;
      background-position: left;
      background-size: 60px;
      span{
      font-family: Noto Sans JP;
          font-size: 12px;
    }
  }
  &-details{
    p{
      font-size: 13px;
    }
}
      }
    }
  }
}


@media screen and (max-width: 767px) {
.home {
  &-registry {
        background-color: #0f3667;
    margin-bottom: 100px;
    &-cont{
      background-position: 30px 36px;
      background-size: 70px;
      padding: 10px;
      color: #fff;
      margin:0px;
      background-color: #0F3667;
      &:first-child{
        border-right:1px solid #fff;
      }
      &:hover{
        background-position: 30px 36px;
        background-size: 70px;
        background-color: #668dbd;
        color: #fff;
      }
      &-content{
                justify-content: center;
     align-items: center;
     padding: 0;
     &-title{
          padding: 0px 0px 0px 40px;
      background:url('../img/common/icon-member.svg')no-repeat;
      background-position: left;
      background-size: 30px;
      white-space: nowrap;
       .title-h4{
         font-size: 16px;
       }
      span{
      font-family: Noto Sans JP;
          font-size: 10px;
    }
  }
  &-details{
    display: none;
}
      }
    }
  }
}
}

.home {
  &-registry {
    &-seminar{
      padding: 20px 10px;
      color: #fff;
      margin:0px;
      background-color: #0F3667;
      &:hover{
        background-color: #668dbd;
        color: #fff;
      }
      &-content{
            justify-content: center;
     align-items: center;
     &-title{
          padding: 0px 0px 0px 80px;
      background-image:url('../img/common/icon-seminar.svg');
      background-repeat: no-repeat;
      background-position: left;
          background-size: 60px;
      span{
      font-family: Noto Sans JP;
          font-size: 12px;
    }
  }
  &-details{
    p{
      font-size: 13px;
    }
}
      }
    }
  }
}


@media screen and (max-width: 767px) {
.home {
  &-registry {
    &-seminar{
      padding: 20px 10px;
      color: #fff;
      margin:0px;
      background-color: #0F3667;
      &:hover{
        background-color: #668dbd;
        color: #fff;
      }
      &-content{
            justify-content: center;
     align-items: center;
     padding: 0;
     &-title{
          padding: 0px 0px 0px 40px;
      background-image:url('../img/common/icon-seminar.svg');
      background-repeat: no-repeat;
      background-position: left;
          background-size: 30px;
          white-space: nowrap;
          .title-h4{
         font-size: 16px;
       }
      span{
      font-family: Noto Sans JP;
          font-size: 10px;
    }
  }
  &-details{
    display: none;
}
      }
    }
  }
}
}



/*
	button（どっか移動させる）
================================*/

.button{
    &-white {
    max-width: 250px;
    width: 100%;
    font-weight: 400;
    display: inline-block;
    padding: 10px;
    border-radius: 30px;
    color: #0F3667;
    padding: 10px;
    box-shadow: 0px 4px 4px rgba(0,0,0,.25);
    transition: all .3s;
    position: relative;
    overflow: hidden;
    z-index: 1;  
    border: none;
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color:#fff;
        border-radius: 30px;
        z-index: -2;
    }
    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: darken(#0f3667, 15%);
        transition: all .3s;
        border-radius: 30px;
        z-index: -1;
    }
    &:hover {
        color: #fff;
        opacity:1;
        border:none;
        &:before {
            width: 100%;
        }
    }
    }

}

.el_btn__naivy{
  display: inline-block;
  max-width: 250px;
  width: 100%;
  font-weight: 400;
  padding:10px;
  background-color: #0F3667;
  color:#fff;
  text-align: center;
  transition: .25;
  border-radius: 30px;
  box-shadow: 0px 4px 4px rgba(0,0,0,.25);
  transition: all .3s;
  overflow: hidden;
  z-index: 1;  
  border: none;
  &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: darken(#0f3667, 15%);
        transition: all .3s;
        border-radius: 30px;
        z-index: -1;
    }
    &:hover {
        color: #fff;
        opacity:1;
        &:before {
            width: 100%;
        }
    }
}

.el_btn__naivy.el_btn__naivy__arrowright{
  position: relative;
  padding-right: 2em;
  padding-left:1.38em
}

.el_btn__naivy.el_btn__naivy__arrowright::after{
  content: url(../img/common/btn-arrow.svg);
  position: absolute;
  height: 20px;
  top: 50%;
  right: .83em;
  transform: translateY(-50%);
}

.el_btn__white{
  display: inline-block;
  max-width: 250px;
  width: 100%;
  font-weight: 400;
  padding:10px;
  background-color: #fff;
  color:#0F3667;
  text-align: center;
  transition: .25;
  border-radius: 30px;
  box-shadow: 0px 4px 4px rgba(0,0,0,.25);
  transition: all .3s;
  overflow: hidden;
  z-index: 1;  
  border: none;
  &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: darken(#0f3667, 15%);
        transition: all .3s;
        border-radius: 30px;
        z-index: -1;
    }
    &:hover {
        color: #fff;
        opacity:1;
        &:before {
            width: 100%;
        }
    }
}

.el_btn__white.el_btn__white__arrowright{
  position: relative;
  padding-right: 2em;
  padding-left:1.38em
}

.el_btn__white.el_btn__white__arrowright::after{
  content: url(../img/common/btn-arrow-naivy.svg);
  position: absolute;
  top:50%;
  right:.83em;
  transform: translateY(-50%);
}



  
/*
	今月のおすすめ物件
================================*/

@media screen and (min-width: 768px) and (max-width: 940px) {
  .recommended{
      padding-top:120px;
  }
}

@media (min-width: 768px) {
  .recommended{
    position: relative;
    padding-bottom:100px;
    max-width: 1440px;
    margin: 0 auto;
    &-column{
      h2{
        position: absolute;
        top: -20px;
      }
      box-shadow: -20px 50px 100px 0px #ccc;
    background-color: #fafafa;
    color: #444;
    position: absolute;
    display: block;
    width: 142%;
    bottom: -20px;
      &-item{
            padding: 5% 30% 5% 8%;
      }
    }
    &-list{
      width: 100%;
        tbody{
          display: block;
          border-top: 1px solid #E5E5E5;
          padding-top: 12px;
        tr{
          border-bottom: 1px solid #E5E5E5;
          padding-bottom: 12px;
          margin-bottom:12px;
          display: flex;
          justify-content: space-between;
          th{
            display: block;
          }
          td{
            width:70%;
            text-align: left;
          }
        }
      }
    }
    &-img{
    position: relative;
    right: -30px;
    img{
      width: 70%;
    height: 500px;
    object-fit: cover;
    }
    } 
  }
  }
  
  @media screen and (max-width: 767px) {
    .recommended{
      display: flex;
      flex-wrap: wrap-reverse;
      img{
        height: 100%;
        max-height: 300px;
        object-fit: contain;
      }
      &-img{
        padding-bottom: 20px;
background: rgb(255,255,255); /* Old browsers */
background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 45%, rgba(255,255,255,1) 52%, rgba(239,239,239,1) 54%, rgba(239,239,239,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 45%,rgba(255,255,255,1) 52%,rgba(239,239,239,1) 54%,rgba(239,239,239,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 45%,rgba(255,255,255,1) 52%,rgba(239,239,239,1) 54%,rgba(239,239,239,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#efefef',GradientType=0 ); /* IE6-9 */
      }
      &-column{
        paddgin-bottom:150px;
        background-color: #fafafa;
        color:#444;
        padding: 8% 12%;
        display: block;
      }
      &-list{
        width: 100%;
          tbody{
          tr{
            border-bottom: 1px solid #E5E5E5;
            width: 100%;
            th{
              display: block;
              margin: 10px;
              width: 50%;
            }
            td{
              width: 70%;
            }
          }
        }
      }
    }
  }
  
  .border-left-blue{
    border-left:8px solid #0f3667;
    padding: 0px 0px 0px 15px;
  }

/*
	slider(移動させる)
================================*/

.slide-inner{
  max-width:1440px;
  margin:0 auto;
}

.slider02{
  position: relative;
  right: -200px;
}

.slider-title h3{
  padding: 50% 0px 0px 26%;
  word-break: keep-all;
}

@media screen and (min-width: 768px) {
  .slider-title{
  position: absolute;
}
}

@media screen and (max-width: 767px) {
  .slider02{
    right:0px;
  }
  .slider-title h3{
   padding:0px;
  }
}

 .home-estate_loop{
   margin: 100px 0 160px 0;
    padding: 80px 0 30px 0;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+8,e3e3e3+8,e3e3e3+8,e3e3e3+100 */
background: rgb(255,255,255); /* Old browsers */
background: -moz-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 8%, rgba(227,227,227,1) 8%, rgba(227,227,227,1) 8%, rgba(227,227,227,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 8%,rgba(227,227,227,1) 8%,rgba(227,227,227,1) 8%,rgba(227,227,227,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 8%,rgba(227,227,227,1) 8%,rgba(227,227,227,1) 8%,rgba(227,227,227,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e3e3e3',GradientType=1 ); /* IE6-9 */

 }




/*
	マンション投資をお考えの方へ
================================*/
@media screen and (min-width: 768px) and (max-width: 940px) {
  .assetanagement{
      padding-top:60px;
  }
}

  @media (min-width: 768px) {
    .assetanagement{
      position: relative;
      padding-bottom:100px;
      flex-direction: row-reverse;
      max-width: 1440px;
      margin: 0 auto;
      &-column{
            box-shadow: 20px 50px 100px 0px #ccc;
        background-color: #fafafa;
        color: #444;
        position: absolute;
        display: block;
        width: 142%;
        bottom: -20px;
        right: 0px;
        h2{
          font-weight: normal;
          position: absolute;
          top: -20px;
        }
        h4{
          color:#0F3667;
        }
        &-item{
          padding: 5% 8% 5% 30%;
        }
      }
      &-list{
        width: 100%;
          tbody{
            display: block;
            border-top: 1px solid #E5E5E5;
            padding-top: 12px;
          tr{
            border-bottom: 1px solid #E5E5E5;
            padding-bottom: 12px;
            margin-bottom:12px;
            width: 100%;
            display: block;
            th{
              width:40%;
            }
            td{
              text-align: right;
            }
          }
        }
      }
      &-img{
        position: relative;
        right: -100px;
        img{
          width: 70%;
        }
      }
    }
    }
    
    @media screen and (max-width: 767px) {
      .assetanagement{
        img{height:100%}
        &-column{
          paddgin-bottom:150px;
          background-color: #fafafa;
          color:#444;
          padding: 8% 12%;
          display: block;
        }
        &-list{
          width: 100%;
            tbody{
            tr{
              border-bottom: 1px solid #E5E5E5;
              padding-bottom: 12px;
              margin-bottom:12px;
              width: 100%;
              display: block;
              th{
                width:40%;
              }
            }
          }
        }
      }
    }

/*
	news-2col
================================*/

.col2-news{
  max-width: 1440px;
  margin:0 auto;
}
.date{
    font-size: 12px;
    font-weight: normal;
}
.category{
    font-size: 10px;
        margin-bottom: 5px;
    display: inline-block;
    font-weight: normal;
}

/*
	採用情報
================================*/

.recruit{
  box-shadow:2px 4px 7px -6px #000;
  position: absolute;
  display: block;
  width: 142%;
  bottom: -20px;
  @media screen and (max-width: 940px) {
    position: initial;
    width: 100%;
    margin-bottom: 30px;
  }

  &-title-inner{
    @media screen and (max-width: 940px) {
      padding: 0 12% 0 5%;
    }
  }

  &-inner{
    padding: 80px 0px 130px 0px;
    position: relative;
    max-width: 1440px;
    margin:0 auto;
    height: fit-content;
    @media screen and (max-width: 940px) {
      padding: 0;
    }
  }
  &-list{
  background-color: #fafafa;
  padding: 3% 34% 3% 8%;
    @media screen and (max-width: 940px) {
      padding: 3% 12% 3% 5%;
    }
  }
  &-arrow{
    position: relative;
    &-inner{
    position: absolute;
    right: -50px;
    top: -70px;
    }
  }
}



// @media (min-width: 768px) {
//   .recruit-inner-sp{
//     display: none;
//   }
// }

@media screen and (min-width: 768px) and (max-width: 940px) {
    .recruit{
    &-list{
    padding: 3% 34% 3% 8%;
    }
  }

}

// @media screen and (max-width: 767px) {
//   .recruit-inner{
//     display:none;
//   }

//   .recruit-inner-sp{
//     background: url(../img/common/recruit-new.png) no-repeat;
//     background-size: cover;
//     color:#fff;
//     padding: 20px 10px;
//   }
// }


/*
	[animation]fage-in
================================*/


// 今月のおすすめ物件、マンション経営をお考えの方へ

// .recommended-img{
//   animation-name: RightToLeft;/* アニメーション名 */
//   animation-duration: 2s;/* アニメーション時間 */
// }

// .assetanagement-img{
//   animation-name: LeftToRight;/* アニメーション名 */
//   animation-duration: 2s;/* アニメーション時間 */
// }



// アニメーションの処理

// translateX(50px) /* 右に50px */
// translateX(-50px) /* 左に50px */
// translateY(50px) /* 上に50px */
// translateY(-50px) /* 下に50px */

/* 右から左へ*/
@keyframes RightToLeft {
0% {
    opacity: 0;/* 透明 */
    transform: translateX(100px);/* X軸方向に50px */

}
100% {
    opacity: 1;/* 不透明 */
    transform: translateX(0);/* X軸方向に0px */
}
}

/* 左から右へ*/
@keyframes LeftToRight {
0% {
    opacity: 0;/* 透明 */
    transform: translateX(-100px);/* X軸方向に50px */

}
100% {
    opacity: 1;/* 不透明 */
    transform: translateX(0);/* X軸方向に0px */
}
}

/*下から上*/
@keyframes SlideUp {
0% {
    opacity: 0;/* 透明 */
    transform: translateY(100px);/* Y軸方向に50px */
}
100% {
    opacity: 1;/* 不透明 */
    transform: translateY(0);/* Y軸方向に0px */
}
}

// .fadein,.fadein-rtol,.fadein-ltor,.fadein-sideup {
//   opacity: 0;
//   transition : all 1500ms;
// }

// .fadein.scrollin{
//   opacity: 1;
//   transform: translate(0, 0);
// }

// .fadein-rtol.scrollin-rtol{
//   opacity: 1;
//   animation-name: RightToLeft;
//   animation-duration: 2s;/* アニメーション時間 */
// }

// .fadein-ltor.scrollin-ltor{
//   opacity: 1;
//   animation-name: LeftToRight;
//   animation-duration: 2s;/* アニメーション時間 */
// }

// .fadein-sideup.scrollin-sideup{
//   opacity: 1;
//   animation-name: LeftToRight;
//   animation-duration: 2s;/* アニメーション時間 */
// }

/*▼▼ ここから追加する ▼▼*/
.loader-wrap {
	position: fixed;
	display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: #fff;
    top: 0;
    z-index: 1;
}
/*▲▲ ここまで追加する ▲▲*/

.loader,
.loader:before,
.loader:after {
	background: #000;
	-webkit-animation: load1 1s infinite ease-in-out;
	animation: load1 1s infinite ease-in-out;
	width: 1em;
	height: 4em;
}
.loader {
	color: #ffffff;
	text-indent: -9999em;
	margin: 88px auto;
	position: relative;
	font-size: 11px;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}
.loader:before,
.loader:after {
	position: absolute;
	top: 0;
	content: '';
}
.loader:before {
	left: -1.5em;
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}
.loader:after {
	left: 1.5em;
}
@-webkit-keyframes load1 {
	0%,
	80%,
	100% {
		box-shadow: 0 0;
		height: 4em;
	}
	40% {
		box-shadow: 0 -2em;
		height: 5em;
	}
}
@keyframes load1 {
	0%,
	80%,
	100% {
		box-shadow: 0 0;
		height: 4em;
	}
  	40% {
		box-shadow: 0 -2em;
		height: 5em;
	}
}



/*===============================
    フローティングボタン
================================*/

.block{
  display:block;
}

@media screen and (max-width: 767px){
  .sp-hidden{
    display:none !important
  }
}


.float-button__wrap {
  display:none;
  width:100%;
  height:fit-content;
  padding:15px 30px;
  position:fixed;
  bottom:0;
  right:0;
  z-index:10;
  background-color:#fff;
  filter:drop-shadow(5px 5px 5px #4e4e4e);

  .fixed-menu-tel{
    text-align: center;
    p{
      font-size: 12px;
      img{
        width: 30px;
      }
      span{
        font-size:30px;
      }
    }
  }

  .fixed-menu-login{
    display:flex;
    justify-content:center;
    align-items:center;

    a{
      height:50px;
      width:100% !important;
      max-width:100% !important;
      background-color:#0F3667;
      color:#fff;
      border-radius:50px;
      display:flex;
      justify-content:center;
      align-items:center;
    }
  }
  .fixed-menu-requestinfo{
    display:flex;
    justify-content:center;
    align-items:center;

    a{
      height:50px;
      width:100% !important;
      max-width:100% !important;
      background-color:#0F3667;
      color:#fff;
      border-radius:50px;
      display:flex;
      justify-content:center;
      align-items:center;
    }
  }
  @media screen and (max-width: 767px){
    .sp-hidden{
      display:none !important;
  }
}
}

/*===============================
    採用情報
================================*/
.employment{
  margin: 0 auto;
  margin-bottom: 80px;
  margin-top: 80px;

  &-menu{
    padding: 0;

    &-title{
      padding: 0 5%;
    }
    &-btn{
      background-color: #fafafa;
      padding: 3% 7% 3% 5%;
      border-bottom: 1px solid #E5E5E5;
      display: flex;
      box-shadow: 20px 50px 100px 0px #ccc;
      @media screen and (max-width: 767px){
        padding: 3% 5%;
      }

      &-text{
        width: 100%;
        padding-right: 30px;
        p{
          margin: 0;
        }
      }

      &-icon{
        width: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 100%;
        }
      }
    }
  }

  &-video{
    padding: 0;
    width: 100%;
    height: 400px;
    overflow: hidden;
    height: fit-content;

    @media screen and (max-width: 767px){
      margin-left: 0;
      margin-bottom: 0;
    }

    video{
      width: 100%;
    }
  }
}

.instagram{
  margin-bottom: 150px;
  text-align: center;
  p{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    margin-bottom: 10px;

    img{
      width: 18px;
      margin-right: 10px;
    }
  }
}