/*===============================
契約フローページ
================================*/

/* 左のフローナンバー */
.flow {
  display: inline-block;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  text-align:center;
  line-height: 140px;
  border: 2px solid #0F3667;
  box-sizing: border-box;
  position: relative;

  &-number {
    position: absolute;
    width: 54px;
    height: 38px;
    font-size: 48px;
    line-height: 140px;
    color: #0F3667;
    left: 35px;
  }  
}

/* 矢印 */
.triangle {
  position: absolute;
  transform: rotate(-180deg);
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #0F3667;
  left: 140px;
}



@media screen and (max-width: 1024px) {
  .triangle {
    left: 90px;
  }    
}

@media screen and (max-width: 768px) {
  .triangle {
    left: 65px;
  }    
}

@media screen and (max-width: 375px) {
  .triangle {
    left: 170px;
  }    
}

@media screen and (max-width: 320px) {
  .triangle {
    left: 140px;
  }    
}