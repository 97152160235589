

%clearfix{
  &:after{
    content: "";
    display: block;
    height: 0;
    clear: both;
  }
}
.clear{
  clear: both;
  display: block;
}

%block-link{
  display: block;
  &:link, &:visited{
    text-decoration: none;
  }
  &:hover, &:active{
    text-decoration: none;
  }
}

.overflow{
  &-hidden{
    overflow: hidden;
  }
}

/*【会員登録のボタン】*/

.button {
  background: none;
  line-height: 1;
  background-color: #3bb3e0;
  padding: 10px;
  position: relative;
  text-decoration: none;
  color: #fff;
  width: 50%;
  font-size: 14px;
  border: none;
  margin-bottom: 30px;
  background-image: linear-gradient(bottom, #2ca0ca 0%, #3eb8e5 100%);
  background-image: -webkit-linear-gradient(bottom, #2ca0ca 0%, #3eb8e5 100%);
  background-image:-ms-linear-gradient(bottom, #2ca0ca 0%, #3eb8e5 100%); /* IE */
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, #2ca0ca), color-stop(1, #3eb8e5));
  -o-box-shadow: inset 0px 1px 0px #7fd2f1, 0px 6px 0px #156785;
  box-shadow: inset 0px 1px 0px #7fd2f1, 0px 6px 0px #156785;
  border-radius: 5px;
  display: inline-block;
}

.button:hover{
  text-decoration: none;
  color: #ffffff;
}

.button::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  padding-left: 2px;
  padding-right: 2px;
  padding-bottom: 4px;
  left: -2px;
  top: 5px;
  z-index: -1;
  border-radius: 6px;
  -o-box-shadow: 0px 1px 0px #fff;
  box-shadow: 0px 1px 0px #fff; }

.button:active {
  color: #156785;
  text-shadow: 0px 1px 1px rgba(255, 255, 255, 0.3);
  background: #2ca0ca;
  -o-box-shadow: inset 0px 1px 0px #7fd2f1, inset 0px -1px 0px #156785;
  box-shadow: inset 0px 1px 0px #7fd2f1, inset 0px -1px 0px #156785;
  top: 7px; }

.buttons:active::before {
  top: -2px; }

/*【border】*/

.border{
  &-gray{
    &-all{
      border: 1px solid #bcbcbc;
    }
    &-bottom{
      border-bottom: 1px solid #bcbcbc;
    }
    &-left{
      border-left: 1px solid #bcbcbc;
    }
    &-right{
      border-right: 1px solid #bcbcbc;
    }
  }
  &-lightgray{
    &-bottom{
      border-bottom: 1px solid #E5E5E5;
    }
  }
  &-black{
    &-all{
      border: 1px solid #444444;
    }
    &-bottom{
      border-bottom: 1px solid #444444;
    }
    &-right{
      border-right: 1px solid #444444;
    }
  }
  &-white{
    &-left{
      border-left:1px solid #fff;
    }
    &-right{
      border-right:1px solid #fff;
    }
    &-bottom{
      border-bottom: 1px solid #fff;
      &-gray{
      border-bottom: 1px solid #cacaca;
    }
    }
  }
  &-l-none{border-left: none;}
  &-b-none{border-bottom: none;}
}

/*【color】*/

.color{
  &-white{color: #fff;}
  &-black{color: #444444;}
  &-naivy{color:#0F3667;}
  &-gray{color:#898989;}
}

@media only screen and (max-width: 768px) {
  .color{
    &-sp{
      &-naivy{color:#0F3667;}
      &-gray{color:#898989;}
    }
}
}

/*【background】*/

.bg{
  &-white{background-color: #fff;}
  &-glay{background-color: #f1f1f1;}
  &-black{background-color: #000000;}
  &-black2{background-color: #424142;}
  &-black-b{background-color: #242424;}
  &-black-c{background-color: #313131;}
  &-blue{background-color: #3975a7;}
  &-offwhite{background-color: #F8F8F8;}
}

/*【btn】*/

.display{
  &-block{
    display: block;
  }
  &-inline-block{
    display:inline-block;
  }
}

/*【float】*/
.float{
  &-left{float: left;}
  &-right{float: right;}
}

/*【margin】*/
.m{
  &-center{margin: 0 auto;}
  &-all10{margin: 10px;}
  &-top5{margin-top: 5px;}
  &-top40{margin-top: 40px;}
  &-top60{margin-top: 60px;}
  &-top80{margin-top: 80px;}
  &-top100{margin-top: 100px;}
  &-right10{margin-right: 10px;}
  &-right20{margin-right: 20px;}
  &-right30{margin-right: 30px;}
  &-left10{margin-left: 10px;}
  &-left20{margin-left: 20px;}
  &-left30{margin-left: 30px;}
  &-bottom0{margin-bottom: 0;}
  &-bottom5{margin-bottom: 5px;}
  &-bottom10{margin-bottom: 10px;}
  &-bottom14{margin-bottom: 14px;}
  &-bottom15{margin-bottom: 15px;}
  &-bottom16{margin-bottom: 16px;}
  &-bottom20{margin-bottom: 20px;}
  &-bottom30{margin-bottom: 30px;}
  &-bottom40{margin-bottom: 40px;}
  &-bottom60{margin-bottom: 60px;}
  &-bottom80{margin-bottom: 80px;}
  &-bottom100{margin-bottom: 100px;}
}
.p{
  &-all5{padding: 5px;}
  &-all10{padding: 10px;}
  &-all20{padding: 20px;}
  &-top2{padding-top: 2px;}
  &-top5{padding-top: 5px;}
  &-top10{padding-top: 10px;}
  &-top12{padding-top: 12px;}
  &-top14{padding-top: 14px;}
  &-top15{padding-top: 15px;}
  &-top16{padding-top: 16px;}
  &-top20{padding-top: 20px;}
  &-top26{padding-top: 26px;}
  &-top36{padding-top: 36px;}
  &-top40{padding-top: 40px;}
  &-right0{padding-right: 0px;}
  &-right10{padding-right: 10px;}
  &-right12{padding-right: 12px;}
  &-right14{padding-right: 14px;}
  &-right15{padding-right: 15px;}
  &-right16{padding-right: 16px;}
  &-right20{padding-right: 20px;}
  &-left0{padding-left: 0px;}
  &-left10{padding-left: 10px;}
  &-left12{padding-left: 12px;}
  &-left14{padding-left: 14px;}
  &-left15{padding-left: 15px;}
  &-left16{padding-left: 16px;}
  &-left20{padding-left: 20px;}
  &-bottom0{padding-bottom: 0;}
  &-bottom2{padding-bottom: 2px;}
  &-bottom5{padding-bottom: 5px;}
  &-bottom10{padding-bottom: 10px;}
  &-bottom12{padding-bottom: 12px;}
  &-bottom14{padding-bottom: 14px;}
  &-bottom15{padding-bottom: 15px;}
  &-bottom16{padding-bottom: 16px;}
  &-bottom20{padding-bottom: 20px;}
  &-bottom36{padding-bottom: 36px;}
  &-bottom40{padding-bottom: 40px;}
}

/*【width】*/
.w{
  &100{width: 100%;}
  &20{width: 20%;}
  &30{width: 30%;}
  &40{width: 40%;}
  &50{width: 50%;}
  &60{width: 60%;}
  &70{width: 70%;}
  &80{width: 80%;}
  &-10px{width: 10px;}
  &-240px{width: 240px;}
  &-100px{width: 100px;}
}


/*【table】*/

.table {
  tr {
    th {
      border: 1px solid #e2e2e2;
      padding: 10px;
      text-align: center;
      white-space: nowrap;
      font-weight: bold;
    }
    td {
      border: 1px solid #e2e2e2;
      padding: 10px;
      width: 100%;
    }
  }
  tr:nth-child(2n) {
  }
  &-dl {
    border-top: 1px solid #ccc;
    dt {
      float: left;
      width: 120px;
      padding: 16px;
    }
    dd {
      padding-left: 100px;
      background: #f1f1f1;
      padding: 16px;
      border-bottom: 1px solid #ccc;
    }
    dd:nth-child(4n) {
      background: #fff;
    }
  }
  &-blue{
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    tr{
      th{
        text-align: center;
        white-space: nowrap;
        background: #f1f1f1;
        padding: 5px;
        border: 1px solid #e2e2e2;
        word-wrap: break-word;
        white-space: normal;

      }
      td{
        padding: 5px;
        border: 1px solid #e2e2e2;
        vertical-align: middle;
        text-align: center;
        line-height: 1;
        word-wrap: break-word;
      }
    }
    tbody{
      tr{
        th{
          width: 30%;
          box-sizing: border-box;
          vertical-align: middle;
          display: table-cell;
        }
      }
    }
  }
  &-scroll{
    &-head{
      thead{
        tr{
          th{
            width: 102%;
            border-bottom: none;
          }
        }
      }
      tbody{
        tr{
          th{
            display: block;
            width: 102%;
            height: 57px;
            border-bottom: none;
          }
          th:last-child{
            border-bottom: 1px solid #e2e2e2;
            height: 58px;
          }
        }
      }
    }
    &-body{
      width: 730px;
      table-layout: fixed;
      thead {
        tr {
          th:first-child {
            border-left: none;
          }
        }
      }
      tbody{
        tr{
          th{
            width: 200px;
          }
          td{
            height: 46px;
            width: 200px;
            white-space: nowrap;
          }
          td:first-child{
            border-left: none;
          }
        }
      }
    }
  }
}


div {
  *zoom: 1;
}

div:after {
  display: block;
  content: "";
  clear: both;
}

.js-tab,
.js-tabB {
  display: flex;
  font-weight: bold;
  margin-bottom: 20px;
  color: #0F3667;
  letter-spacing: 0.1em;
  position: relative;
}

.js-tab>div,
.js-tabB>div {
  border: 1px #0F3667 solid;
  width: 50%;
  text-align: center;
  padding: 15px 0;
  cursor: pointer;
}

.js-tab>div:first-child,
.js-tabB>div:first-child {
  border-right: none;
}

.js-tab>div.active,
.js-tabB>div.active {
  background: #0F3667;
  color: #fff;
}

.js-tab>div.active:before,
.js-tabB>div.active:before {
  content: "";
  position: absolute;
  border: 15px solid transparent;
  border-top: 15px solid #0F3667;
  top: 54px;
  margin-left: 20px;
}

.js-tab_content,
.js-tab_contentB {
  display: none;
  padding: 20px 0;
}

.js-tab_content.active,
.js-tab_contentB.active {
  display: block;
}




/*【line-height】*/
.lineheight0{line-height: 0 !important;}
.lineheight015{line-height: 1.5 !important;}
.lineheight02{line-height: 2 !important;}

/*【text-align】*/
.text{
  &-center{
    text-align: -moz-center;
    text-align: -webkit-center;
    text-align: center;
  }
}

// 小デバイス（横向きモバイル, 576px 以上）
@media (min-width: 576px) {
  .sm{
    &-br{
      display: none;
    }
    &-border{
      &-n{
        border:none;
      }
    }
    &-flex{
      display: flex;
      &-none{display: block;}
      &-cal2{
        display: flex;
        flex-wrap:wrap;
        &-item{
          width: 47.5%;
          margin-right: 5%;
        }
        &-item:nth-child(2n){
          margin-right: 0px;
        }
        &-item:nth-child(3n){
          margin-right: 5%;
        }
      }
    }
    &-display{
      &-block{display: block;}
    }
    &-w30{width: 30%;}
    &-w35{width: 35%;}
    &-w40{width: 40%;}
    &-w50{width: 50%;}
    &-w60{width: 60%;}
    &-w70{width: 70%;}
    &-w80{width: 80%;}
    &-w90{width: 90%;}
    &-w100{width: 100%;}
    &-h100{height: 100%;}
    &-float{
      &-none{float: none;}
      &-left{float: left;}
      &-right{float: right;}
    }
    &-m{
      &-top-40{margin-top: -40px;}
      &-right10{margin-right: 10px;}
      &-right20{margin-right: 20px;}
      &-right30{margin-right: 30px;}
      &-right12{margin-right: 12px;}
      &-right14{margin-right: 14px;}
      &-right16{margin-right: 16px;}
      &-left10{margin-left: 10px;}
      &-left12{margin-left: 12px;}
      &-left14{margin-left: 14px;}
      &-left16{margin-left: 16px;}
      &-bottom0{margin-bottom: 0;}
      &-bottom2{margin-bottom: 2px;}
      &-bottom10{margin-bottom: 10px;}
      &-bottom12{margin-bottom: 12px;}
      &-bottom14{margin-bottom: 14px;}
      &-bottom16{margin-bottom: 16px;}
    }
    &-p{
      &-all0{padding: 0;}
      &-all4{padding: 4px;}
      &-all10{padding: 10px;}
      &-all20{padding: 20px;}
      &-top2{padding-top: 2px;}
      &-top10{padding-top: 10px;}
      &-top12{padding-top: 12px;}
      &-top14{padding-top: 14px;}
      &-top16{padding-top: 16px;}
      &-top40{padding-top: 40px;}
      &-right10{padding-right: 10px;}
      &-right12{padding-right: 12px;}
      &-right14{padding-right: 14px;}
      &-right16{padding-right: 16px;}
      &-left10{padding-left: 10px;}
      &-left12{padding-left: 12px;}
      &-left14{padding-left: 14px;}
      &-left16{padding-left: 16px;}
      &-bottom0{padding-bottom: 0;}
      &-bottom2{padding-bottom: 2px;}
      &-bottom10{padding-bottom: 10px;}
      &-bottom12{padding-bottom: 12px;}
      &-bottom14{padding-bottom: 14px;}
      &-bottom16{padding-bottom: 16px;}
      &-bottom40{padding-bottom: 40px;}
    }
  }
  .table {
    &-dl {
      dt {
        width: 100%;
        padding: 1.5%;
        font-weight: bold;
      }
      dd {
        background: #f1f1f1;
        padding: 1.5%;
        border-bottom: 1px solid #ccc;
      }
    }
    &-scroll {
      &-head {
        width: 100%;
        thead{
          tr{
            th{
              width: 100%;
            }
          }
        }
        tbody{
          tr{
            th{
              width: 100%;
            }
          }
        }
      }
    }
  }
}

// 中デバイス（タブレット, 768px 以上）
@media (min-width: 768px) {
  .md{
    &-br{
      display: none;
    }
    &-border{
      &-n{
        border:none !important;
      }
    }
    &-wauto{width: auto;}
    &-w200px{width: 200px;}
    &-w280px{width: 280px;}
    &-w300px{width: 300px;}
    &-w460px{width: 460px;}
    &-w720px{width: 720px;}
    &-h{
      &-200px-i{height: 200px !important;}
      &-155px-i{height: 155px !important;}
    }
    &-m{
      &-top-40{margin-top: -40px;}
      &-top15{margin-top: 15px;}
      &-top20{margin-top: 20px;}
      &-top30{margin-top: 30px;}
      &-top60{margin-top: 60px;}
      &-top300{margin-top: 300px;}
      &-right10{margin-right: 10px;}
      &-right20{margin-right: 20px;}
      &-right30{margin-right: 30px;}
      &-right12{margin-right: 12px;}
      &-right14{margin-right: 14px;}
      &-right16{margin-right: 16px;}
      &-right20{margin-right: 20px;}
      &-left10{margin-left: 10px;}
      &-left12{margin-left: 12px;}
      &-left14{margin-left: 14px;}
      &-left15{margin-left: 15px;}
      &-left16{margin-left: 16px;}
      &-left20{margin-left: 20px;}
      &-bottom0{margin-bottom: 0;}
      &-bottom2{margin-bottom: 2px;}
      &-bottom10{margin-bottom: 10px;}
      &-bottom12{margin-bottom: 12px;}
      &-bottom14{margin-bottom: 14px;}
      &-bottom16{margin-bottom: 16px;}
      &-bottom30{margin-bottom: 30px;}
      &-bottom40{margin-bottom: 40px;}
      &-bottom60{margin-bottom: 60px;}
    }
    &-p{
      &-0{padding: 0;}
      &-all10{padding: 10px;}
      &-all20{padding: 20px;}
      &-top2{padding-top: 2px;}
      &-top10{padding-top: 10px;}
      &-top12{padding-top: 12px;}
      &-top14{padding-top: 14px;}
      &-top16{padding-top: 16px;}
      &-top20{padding-top: 20px;}
      &-top25{padding-top: 25px;}
      &-top36{padding-top: 36px;}
      &-top40{padding-top: 40px;}
      &-right0{padding-right: 0;}
      &-right10{padding-right: 10px;}
      &-right12{padding-right: 12px;}
      &-right14{padding-right: 14px;}
      &-right16{padding-right: 16px;}
      &-right20{padding-right: 20px;}
      &-right40{padding-right: 40px;}
      &-left10{padding-left: 10px;}
      &-left12{padding-left: 12px;}
      &-left14{padding-left: 14px;}
      &-left15{padding-left: 15px;}
      &-left16{padding-left: 16px;}
      &-left20{padding-left: 20px;}
      &-left40{padding-left: 40px;}
      &-left60{padding-left: 60px;}
      &-bottom0{padding-bottom: 0;}
      &-bottom2{padding-bottom: 2px;}
      &-bottom10{padding-bottom: 10px;}
      &-bottom12{padding-bottom: 12px;}
      &-bottom14{padding-bottom: 14px;}
      &-bottom16{padding-bottom: 16px;}
      &-bottom20{padding-bottom: 20px;}
      &-bottom25{padding-bottom: 25px;}
      &-bottom30{padding-bottom: 30px;}
      &-bottom36{padding-bottom: 36px;}
      &-bottom40{padding-bottom: 40px;}
    }
  }
  .table {
    &-dl {
      dt {
        width: 30%;
        float: left;
        padding: 1.5% 1.5% 0 1.5%;
        font-weight: bold;
      }
      dd {
        padding: 1.5%;
        padding-left: 33%;
        background: #f1f1f1;
        border-bottom: 1px solid #ccc;
      }
      dd:after {
        content: '';
        display: block;
        clear: both;
      }
    }
  }
}

// 大デバイス（デスクトップ, 992px 以上）
@media (min-width: 992px) {
  // lg
}

// 特大デバイス（ワイド・デスクトップ, 1200px 以上）
@media (min-width: 1200px) {
  // xl
}

