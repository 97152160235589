/*【container】*/

.container{
  max-width:1440px;
}

main{
  overflow: hidden;
}

/*【pagenav】*/

.pagenav {
  overflow: hidden;
  .pagenav-content{
    list-style: none;
    position: relative;
    left: 50%;
    float: left;
    li {
      margin: 0 1px;
      position: relative;
      left: -50%;
      float: left;
      span,a {
        display: block;
        padding: 10px 17px;
        border-radius: 50%;
        background-color: #EAEAEA;
        color:#132850;
        margin-right: 15px;
      }
       a:hover{
         background-color: #0F3667;
         color: #fff;
         border-radius: 50%;
       }
       .current {
         color:#ffffff;
       }
    }
    &:after{content:".";display:block;height:0;font-size:0;clear:both;visibility:hidden}
  }
  &-link-box{
    .current{
      color: #fff;
      background-color: #174c6b !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .pagenav {
    .pagenav-content{
      li {
        span,a {
          display: block;
          padding: 10px 16px;
          border-radius: 50%;
          background-color: #EAEAEA;
          color:#132850;
          margin-right: 10px;
        }
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .pagenav {
    .pagenav-content{
      li {
        span,a {
          display: block;
          padding: 7px 12px;
          border-radius: 50%;
          background-color: #EAEAEA;
          color:#132850;
          margin-right: 7px;
        }
      }
    }
  }
}
