/*------------------------------------*\
    Header
\*------------------------------------*/

#mainvisual {
  background-repeat: no-repeat;
  background-size: cover;
  height: 650px;
}
.bx {
  height: 400px !important;
}


/* ==================================
h e a d e r  m i d d l e  c o n t e n t
================================== */

.header{
  &-left01{
    width: 30px;
    img{
      width: 100%;
    padding: 18px 10px 0 0;
    }
  }
}

// 明和グループの〜のテキストBOX

.header {
  &-subtext {
    &-content {
      background-color: #F8F8F8;
      &:after {
        content: "";
        display: block;
        height: 1px;
        width: 100%;
        background: #E5E5E5;
      }
    }
  }
  &-logo {
    width: 70%;
    img{
      width:100%;
      margin:12px 0;
    }
  }
}

// 会員ログイン、資料請求のリンク

.middle-info {
  li {
    a {
      padding: 8px 0px;
      display: block;
      &:hover {
      }
      img {
        margin-right: 5px;
        vertical-align: middle;
        margin-bottom: 3px;
      }
    }
    span {
      img {
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  header {
    h1 {
      img {
        width: 100%;
      }
    }
  }
  .header-logo-img{
    max-width: 200px;
  }
  .header {
    &-logo {
      margin-top: 0px;
      padding: 10px 0;
      max-width: 250px;
      width: 100%;
    }
  }
  .middle-info {
    letter-spacing: 0.1em;
    white-space: nowrap;
    padding-right: 0px;
    li {
      font-size: 12px;
      margin-top: 0;
      &:last-child {
      }
      img {
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1320px) {
.sp-wrap{
  flex-wrap: wrap;
}
ul.menu_submenu a:nth-child(2n) {
    margin-left: 0px;
}
}

/* ==================================
h e a d e r  c o n t a c t
================================== */

.header-contact{
  border-bottom: 1px solid #E5E5E5;
  &-icon{
    margin: 14px 0px;
    img{
    width: 48px;
    padding-right: 10px;
    display: inline-block;
    vertical-align: -2px;
  }
    span{
    font-size: 33px;
    font-family: antarctican-headline, sans-serif;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 8px;
    }
  }
}

// search
.search{
  &-box{
    padding: 10px 20px;
    border-left: 1px solid #e5e5e5;
    position: relative;
  }
}

/* ==================================
sp-navi
================================== */

.overlay-container{
  ul{
    padding: 0px;
  }
  ul li{
    margin-bottom: 4px;
  }
  ul > li > a{
    font-size: 16px;
  }
   ul > li > dt{
     font-size: 16px;
     font-weight: bold;
   }
}

.overlay-box-tel{
  font-size: 28px;
  font-family: antarctican-headline, sans-serif;
  font-weight: 500;
  font-style: normal;
}

@media screen and (max-width: 320px) {
  .overlay-container{
    ul{
      padding-top: 20px;
    }  
  }
}


/* ==================================
navi
================================== */

#content{
  max-width:1440px;
  margin: 0 auto;
}

.overlay-sub-header{

}

.overlay-hugeinc.close{
  .overlay-sub-header{
    visibility: hidden;
  }
}

.close:hover{
  opacity: 0;
}

.slider{
  position: absolute;
  top: 0;
  right: 0;
  padding: 80px 200px;
}

@media screen and (max-width: 767px) {
  .slider{
    padding: 80px 0;
    text-align: center;
    height: 100%;
}
}


.main-heading{
  display: inline-block;
  position: absolute;
  left: 150px;
}

@media screen and (max-width: 767px) {
  .main-heading{
    display: inline-block;
    position: absolute;
    top: 0px;
    left: 70px;
}
}

@media screen and (max-width: 320px) {
  #trigger-overlay {
    margin-top: 25px;
  }
  .overlay-close {
    margin-top: 17px;
  }
  .main-heading{
    left: 38px;
}
}

.layerfade{
  animation: 3s fadeIn forwards; /* ←追加 */
  opacity: 0;
}

.layer01{
  animation-delay: 2s;
  max-width: 250px;
}

.layer02{
  position: absolute;
  top: 55px;
  padding-left: 20px;
  animation-delay: 4s; 
}

.layer03{
  max-width: 350px;
  padding-left: 70px;
  animation-delay: 3s; 
}

@keyframes fadeIn { /* ←追加 */
  from { opacity: 0; }
  to { opacity: 1; }
}

@media screen and (max-width: 1280px) {
  .layer01{
    max-width: 200px;
}
  .layer02{
    top: 45px;
    padding-left: 10px;
    width: 120px;
}
  .layer03{
  max-width: 300px;
}
}

/* ==================================
ヘッダー修正箇所
================================== */

section p{
  top: 2% !important;
}

.overlay .overlay-close{
  @media only screen and (max-width: 1280px){
    top: 3.5%;
  }
}

// @media only screen and (min-width: 1279px){
//   .mobile-navi{
//       display: none !important;
//   }
// }
// @media only screen and (max-width: 1280px){
//   .pc-navi{
//     display: none !important;
//   }
// }