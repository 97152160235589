/*===============================
	コンテンツ共通
================================*/

.menu_container{
  white-space: nowrap;
}


.m-tb80-center{
  margin:80px auto !important;
}


.title{
  &-h1{
    border-bottom: 1px solid #ccc;
    padding-bottom: 30px;
    margin-bottom: 70px;
    font-size: 36px;
  }
  &-h2{
    border-left: 8px solid #0F3667;
    padding-left: 10px;
    font-weight: normal;
    margin-bottom: 30px;
    //font-size: calc(2rem + ((1vw - 0.576rem) * 0.2291));
  }
}


/*===============================
	5つの強み
================================*/

.list{
  counter-reset:item;
  &-item{
    position: relative;
    margin-bottom: 70px;
    &:after {
      content: ".";
      display: block;
      height: 0;
      font-size: 0;
      clear: both;
      visibility: hidden
  }
    &-title{
      font-size: 24px;
      &:before{
        counter-increment:item;
        content: counter(item);
        background-color: #0F3667;
        border-radius: 100px;
        padding: 6px 16px;
        color: #fff;
        font-size: 20px;
        margin-right: 10px;
        float: left;
        margin-top: -5px;
    margin-bottom: 20px;
      }
    }
    &-text{
      font-size: 18px;
      background-color: #f1f1f1;
      padding: 30px;
    }
    &-img{
      float: left;
      margin-right: 30px;
      &-r{
        float: right;
        margin: 0;
        margin-left: 30px;
      }
        }
  }
}

@media screen and (max-width: 1279px) {
  .list{
    &-item{
      &-text{
        font-size: 16px;
      }
      &-img{
        float: none;
        margin-right: 0px;
        margin-bottom: 16px;
        &-r{
          float: none;
          margin: 0;
          margin-bottom: 16px;
          img{
            width: 100%;
          }
        }
        img{
          width: 100%;
        }
          }
    }
  }
}








