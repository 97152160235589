
@media only screen and (max-width: 1280px){
	/* Overlay style */
	.overlay {
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0;
		background: #0F3667;
		color: #fff;;
	}
	
	/* Overlay closing cross */
	.overlay .overlay-close {
	position: absolute;
    top: 5%;
    right: 0;
    background: 0 0;
    z-index: 100;
    padding: 0 10px;
	border: 0;
	}
	
	/* Menu style */
	.overlay nav {
		/* text-align: center; */
		position: relative;
		top: 32%; /*top:50% */
		height: 60%;
		/* font-size: 54px; */
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
		padding: 0px 10px;
	}
	
	.overlay ul {
		list-style: none;
		/* padding: 0;
		margin: 0 auto;
		display: inline-block;
		height: 100%;
		position: relative; */
		padding-left:10px;
	}
	
	.overlay ul li {
		display: block;
		/* height: 20%; */
		/* height: calc(100% / 5);
		min-height: 54px; */
	}
	
	.overlay ul li a {
		display:inline-block;
		color: #fff;
		-webkit-transition: color 0.2s;
		transition: color 0.2s;
	}
	
	.overlay ul li a:hover,
	.overlay ul li a:focus {
		
	}
	
	/* Effects */
	.overlay-hugeinc {
		opacity: 0;
		visibility: hidden;
		-webkit-transition: opacity 0.5s, visibility 0s 0.5s;
		transition: opacity 0.5s, visibility 0s 0.5s;
	}
	
	.overlay-hugeinc.open {
		opacity: 1;
		visibility: visible;
		-webkit-transition: opacity 0.5s;
		transition: opacity 0.5s;
		z-index: 100;
		overflow: scroll;
	}

	
	.overlay-hugeinc nav {
		-webkit-perspective: 1200px;
		perspective: 1200px; 
	}
	
	.overlay-hugeinc nav ul {
		opacity: 0.4;
		-webkit-transform: translateY(-25%) rotateX(35deg);
		transform: translateY(-25%) rotateX(35deg);
		-webkit-transition: -webkit-transform 0.5s, opacity 0.5s;
		transition: transform 0.5s, opacity 0.5s;
	}
	
	.overlay-hugeinc.open nav ul {
		opacity: 1;
		-webkit-transform: rotateX(0deg);
		transform: rotateX(0deg);
	}
	
	.overlay-hugeinc.close nav ul {
		-webkit-transform: translateY(25%) rotateX(-35deg);
		transform: translateY(25%) rotateX(-35deg);
	}
	
	.overlay-box{
		background-color: #fff;
		padding:2% 4%;
	}
	
	@media screen and (max-height: 30.5em) {
		.overlay nav {
			height: 70%;
			font-size: 34px;
		}
		.overlay ul li {
			min-height: 34px;
		}
	}
	}




	