/*===============================
会社概要
================================*/

dl.d-flex {
    border-top: 1px solid #e5e5e5;
    dl:last-child {
    }
    dt {
        width: 30%;
        text-align: center;
        font-weight: normal;
        text-align: left;
        padding-left: 20px;
    }
    dd {
        width: 70%;
    }
    dd:last-child {
    }
}

.bg-company{
    background:url(../img/company-profile/bg-company.png)no-repeat;
    height: 360px;
    background-size: cover;
    margin-bottom: 140px;
    position: relative;
}

.comapany-profile-philosophy{
    padding: 30px 35px;
    background-color: #fafafa;
    box-shadow:2px 4px 7px -6px #000;
    font-family: 'Noto Serif JP', serif;
    p:last-child{
        margin-bottom:0px;
    }
}


@media screen and (min-width: 940px){
    .comapany-profile-philosophy{
    bottom: -94px;
    right: 0px;
    max-width:100%;
    width:768px;
    position: absolute;
    p:last-child{
        margin-bottom:0px;
    }
}
}

@media screen and (min-width: 941px) and (max-width: 1200px) {
    .comapany-profile-philosophy { 
    width:540px;
    bottom: -98px;
    }
}

// @media screen and (min-width: 768px) and (max-width: 940px) {
//     .comapany-profile-philosophy { 
//     width:430px;
//     bottom: -98px;
//     }
// }

@media screen and (min-width: 347px) and (max-width: 540px) {
    .comapany-profile-philosophy{
        position: relative;
        bottom: -120px;
    }
}


@media screen and (min-width: 541px)  and(max-width: 939px){
    .comapany-profile-philosophy { 
    position: relative;
    bottom: -168px;
    }
}

@media screen and(max-width: 939px){
    .comapany-profile{
        padding: 0px 15px;
    }
    .bg-company{
    background-size: contain;
    margin-bottom:80px;
    p{
        font-size: 14px;
    }
    }
}

#map{
    height: 400px;
    width: 100%;
}
