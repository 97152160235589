/*===============================
	会員登録
================================*/

#swpm-registration-form {

}

#display_alert {
  text-align: center;
  background: #669dd1;
  color: #FFF;
  font-weight: bold;
  padding: 5px;
  margin-bottom: 20px;
}

.scroll{
  overflow: scroll visible;
    height: 200px;
    padding: 30px;
    border: 1px solid #ccc;
    margin-bottom: 20px;
}