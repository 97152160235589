/*===============================
オーナーボイス
================================*/

.common_b{
color:#6395c6;
}

.owners-text {
  font-size: 14px;
}

.owner-archive{
  &-title{
    font-size: 15px;
    font-weight: bold;
    color:#0F3667;
    border-bottom: 1px solid #C4C4C4;
    text-align: left;
  }
  &-text{
    font-size:12px;
  }
}


/* single page */
.profile {
  position: relative;
  width: 600px;
  display: flex;
  left: 350px;
  top: 100px;
  z-index: 10;
  background: #FAFAFA;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 150px;
  &-title {
    left: 75px;
    top: 15px;
    font-size: 17px;  
  }
  &-text {
    left: 75px;
    top: -5px;
  }
  &-name {
    top: 60px;
    left: 420px;
  }
}
.pic {
  position: absolute;
  z-index: 20;
}
.text-size {
  font-size: 15px;
}
@media screen and (max-width: 1355px) {
  .profile {
    position: relative;
    width: 500px;
    display: flex;
    left: 320px;
    top: -60px;
    margin-bottom: 0px;
    &-title {
      left: 60px;
      top: 20px;
    }
    &-text {
      left: 60px;
      top: -5px;
    }
    &-name {
      top: 20px;
      left: 0px;
      margin-top: 15px;
    }
  }
  .pic {
    position: relative;
  }
}
@media screen and (max-width: 1210px) {
  .profile {
    position: relative;
    width: 500px;
    display: flex;
    left: 280px;
    top: -60px;
    margin-bottom: 0px;
    &-title {
      left: 60px;
      top: 20px;
    }
    &-text {
      left: 60px;
      top: -5px;
    }
    &-name {
      top: 20px;
      left: 0px;
      margin-top: 15px;
    }
  }
  .pic {
    position: relative;
  }
}
@media screen and (max-width: 1130px) {
  .profile {
    position: relative;
    width: 480px;
    display: flex;
    left: 280px;
    top: -40px;
    margin-bottom: 0px;
    &-title {
      left: 60px;
      top: 20px;
    }
    &-text {
      left: 60px;
      top: -5px;
    }
    &-name {
      top: 20px;
      left: 0px;
      margin-top: 15px;
    }
  }
  .pic {
    position: relative;
  }
}

@media screen and (max-width: 1024px) {
  .profile {
    position: relative;
    width: 100%;
    display: flex;
    left: 0px;
    top: 0px;
    margin-bottom: 40px;
    margin-top: 10px;
    &-title {
      left: 20px;
      top: 20px;
    }
    &-text {
      left: 20px;
      top: -5px;
    }
    &-name {
      top: -15px;
      left: -10px;
      margin-top: 15px;
    }
  }
  .pic {
    position: relative;
  }
}

@media screen and (max-width: 480px) {
  .profile {
    position: relative;
    width: 100%;
    display: flex;
    left: 0px;
    top: 0px;
    margin: auto 0;
    margin-bottom: 30px;
    margin-top: 20px;

    &-title {
      left: 0px;
      top: 10px;
    }
    &-text {
      left: 0px;
      top: -5px;
    }
    &-name {
      top: 0px;
      left: 0px;
      margin-top: 15px;
    }
  }
  .pic {
    position: relative;
  } 
}