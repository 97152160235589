/*------------------------------------*\
    Footer
\*------------------------------------*/

.footer{
    &-link {
        color: #444;
        .bg1 {
            height: 100%;
            background: url(../img/common/footer-img-white.png) no-repeat;
            background-position: center center;
            background-size: 100% 100%;
            &-summary {
                margin: 4% 14%;
                background-color:#fff;
                padding: 2%;
            }
        }

        &-inner{
            display:flex;
            justify-content:center;
            align-items:center;
        }
    }
    &-copy {
        background-color: #2b2c2c;
    }
}

@media screen and (min-width: 769px){
.footer{
    &-link {
        .bg1 {
            &-summary {
                .title-h3{
                    text-align: center;
                    margin: 8% 30%;
                }
            }
        }
    }
    &-copy {
        background-color: #2b2c2c;
    }
}
}

@media screen and (min-width: 769px) and (max-width: 940px) {
    .footer{
    &-link {
        .bg1 {
            &-summary {
                margin:8%;
            }
        }
    }
}
}

@media only screen and (max-width: 768px) {

.footer{
    &-link {
        .bg1 {
            background: none;
            &-summary {
                margin: 0;
                background-color: #fff;
                padding: 0;
                margin-top: 40px;
            }
        }
    }
}
}

/*
	footer-contact
================================*/

.footer{
    &-info{
        max-width: 1440px;
        margin:0 auto;
    }
}

.footer{
    &-tel{
    font-size: 34px;
    font-size: calc(3.4rem + ((1vw - 0.576rem) * 0.4582));/* 10px~14pxで可変*/
    font-family: antarctican-headline, sans-serif;
    font-weight: 200;
    letter-spacing: 6px;
    img{
        width: 46px;
    }
    }
}

@media only screen and (max-width: 768px) {
    .footer{
    &-tel{
    font-size:42px;
    font-size: calc(4.2rem + ((1vw - 0.576rem) * 0.4582));
    letter-spacing: 8px;
    img{
        width:60px;
        }
        }
    }
}

@media screen and (min-width: 769px) and (max-width: 940px) {
    .footer{
    &-tel{
    font-size: 32px;
    font-size: calc(3.2rem + ((1vw - 0.576rem) * 0.4582));
    }
}
}

/*
	copylidht
================================*/
@media only screen and (max-width: 768px) {
    .copytext{
    &-inner{
    text-align: center;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
        }
    }
    
}
