abbr,
address,
article,
aside,
audio,
b,
blockquote,
body,
canvas,
caption,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
p,
pre,
q,
samp,
section,
small,
span,
strong,
summary,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
ul,
var,
video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    vertical-align: baseline;
    background: 0 0
}

fieldset,
img {
    border: 0
}

address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
    font-style: normal;
    font-weight: 400
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 100%;
    font-weight: 400
}

body {
    line-height: 1
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block
}

li,
nav ul {
    list-style: none
}

blockquote,
q {
    quotes: none
}

blockquote:after,
blockquote:before,
q:after,
q:before {
    content: '';
    content: none
}

a {
    margin: 0;
    padding: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: 0 0
}

ins {
    background-color: #ff9;
    color: #000;
    text-decoration: none
}

mark {
    background-color: #ff9;
    color: #000;
    font-style: italic;
    font-weight: 700
}

del {
    text-decoration: line-through
}

abbr[title],
dfn[title] {
    border-bottom: 1px dotted;
    cursor: help
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

caption,
th {
    text-align: left
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0
}

input,
select {
    vertical-align: middle
}

q:after,
q:before {
    content: ''
}

abbr,
acronym {
    border: 0;
    font-variant: normal
}

sup {
    vertical-align: super;
}

sub {
    vertical-align: sub;
}

input,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    *font-size: 100%
}

legend {
    color: #000
}

// @charset "utf-8";
body {
    font: 14px/1.231 arial, helvetica, clean, sans-serif;
    *font-size: small;
    *font: x-small
}

button,
input,
select,
textarea {
    font: 99% arial, helvetica, clean, sans-serif
}

table {
    font: 100%
}

code,
kbd,
pre,
samp,
tt {
    font-family: monospace;
    *font-size: 108%;
    line-height: 100%
}

body {
    line-height: 1.6;
    font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", arial, helvetica, clean, sans-serif;
    color: #444444;
    background: #fff;
    -webkit-text-size-adjust: 100%;
    font-feature-settings : "palt";
    letter-spacing: .1em;
}


a {
    color: #444444;
    text-decoration: none
}

.linkcolor,
a:active,
a:focus,
a:hover {
    color: #3975A7;
    text-decoration: underline
}

h1,
h2,
h3,
h4,
h5,
h6,
strong {
    font-weight: 700;
    line-height: 1.6;
}

p {
    margin-bottom: 1.6em
}

.clear {
    clear: both
}

.clearfix:after {
    content: ".";
    display: block;
    height: 0;
    font-size: 0;
    clear: both;
    visibility: hidden
}

* html .clearfix {
    height: 1%
}

.clearfix {
    display: block
}